import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import SongComponent from '../components/song/Song';
import model from '../resources/model/model';
import './SongPage.scss';

let prevFunct = () => { };
let nextFunct = () => { };
window.addEventListener("keyup", (e) => {
    if (e.key === "ArrowRight") {
        nextFunct();
    }
    if (e.key === "ArrowLeft")
        prevFunct();
})

export default function SongPage() {
    const song = model.currentSong || {};
    const songIDs = model.songIDs;
    const next = songIDs[(songIDs.indexOf(song.id) + 1) % (songIDs.length)]
    const prev = songIDs[(songIDs.indexOf(song.id) + songIDs.length - 1) % (songIDs.length)]

    const navigate = useNavigate()

    const handlers = useSwipeable({
        onSwipedLeft: () => { navigate("/" + next) },
        onSwipedRight: () => { navigate("/" + prev) },
    })
    prevFunct = () => { navigate("/" + prev) }
    nextFunct = () => { navigate("/" + next) }

    return <>
        <Link className="notA" to={"/" + prev}><div className="prev"><i className="fas fa-chevron-left"></i></div></Link>
        <Link className="notA" to={"/" + next}><div className="next"><i className="fas fa-chevron-right"></i></div></Link>
        <div className="page" id="song-page" {...(model.settings.swipeGesturesPrevNext ?handlers:{})}>
            <SongComponent
                song={song}
                columns={model.settings.columns}
                fontSize={model.settings.fontSize}
                trans={model.settings.transpose}
                transposeCapo={model.settings.transposeCapo}
            />
        </div>
    </>
}