import React from 'react';
import Slider from 'rc-slider'


export default class TestPage extends React.Component {
    
    render() {
        return <div className='page'>
          <h1>Test page</h1>
          <Slider
          />
        </div>
    }
}