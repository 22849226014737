import React from "react";
import model from "../../../resources/model/model";
import  Setting from "../../../components/setting/Setting"
import NumberInput from "../../../components/numberInput/NumberInput";

export default function ButtonsSection() {
  const settings = model.settings;
  return (
    <div className="section">
        <Setting
        name="showRandomSongButton"
        title="Random song"
        text="Show random song button."
      />
      <Setting
        name="showYTButton"
        title="YouTube"
        text="Show button with link to youtube search of the current song."
      />
      <Setting
        name="showFavorites"
        title="Favorites"
        text={'Show "Add to favorites" button and sort songs by favorites.'}
      />{settings.showFavorites&&<Setting indent
        name="separateFavorites"
        title="Separate favorites"
        text={"Show favorite song separately at the top and then again in all songs."}
      />}
      <Setting
        name="showAutoFitButton"
        title="Auto-fit"
        text={
          "Show autofit button to automatically adjust font-size to fill the entire screen."
        }
      />
      <Setting
        name="showFontSizeButton"
        title="Font size"
        text={"Show manual font size adjustment button."}
      />
      {settings.displayChords && (
        <Setting
          name="showTranspositionButton"
          title="Transposition"
          text={"Show transposition button."}
        />
      )}
      {settings.displayChords && settings.showTranspositionButton && (
        <Setting
          indent
          name="resetTranspositions"
          title="Auto-reset transposition"
          text="Transposition will be automatically reset each time you select a song."
        />
      )}
      {settings.displayChords && settings.showTranspositionButton && (
        <Setting
          indent
          name="transposeCapo"
          title="Transpose capo"
          text="Show capo needed to stay in original song key after transposition."
        />
      )}
      <Setting
        name="showColumnsButton"
        title="Columns"
        text={"Show column setting button."}
      />
      {settings.showColumnsButton&&<Setting indent
        name="maxColumns"
        title="Max columns"
        text={"Maximum number of columns."}
        input={<NumberInput
            min={1}
            defaultValue={model.setting("maxColumns")}
            onChange={v => model.setSettings({maxColumns: v})}
        />}
      />}

      <Setting
        name="showLineBreakButton"
        title="Line Break"
        text={"Show line break button."}
      />
      <Setting
        name="showEditButton"
        title="Edit"
        text="Show edit button."
      />
    </div>
  );
}
